<template>
  <b-modal
    id="change-model-ownership-modal"
    centered
    size="md"
    title="Change Model Owner"
    ok-title="Change Owner"
    @ok="onSubmit"
    @cancel="onReset"
    @hidden="onReset"
  >
    <b-form-group label="Username">
      <b-form-input
        v-model="newOwner"
        :placeholder="getUserUsername(existingOwner)"
        autocomplete="off"
        autocapitalize="off"
      />
    </b-form-group>
    <div class="d-inline-flex w-100 justify-content-start align-items-center">
      <b-checkbox v-model="shareWithMe">
        Share with the current owner
      </b-checkbox>
      <LabelWithHoverIcon popover-text="Share this model with the current owner (you), otherwise you will lose access to this model after changing ownership."
        class="pb-50 ml-50"
      >
        <template v-slot:label-text>
          <span class="mr-50">Share model with myself</span>
        </template>
      </LabelWithHoverIcon>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Discard
      </b-button>

      <b-button
        variant="danger"
        :disabled="loading"
        @click="ok()"
      >
        <span v-if="loading">
          <b-spinner small type="grow" />
          Changing Owner...
        </span>
        <span v-else>
          Change Owner
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { ref, computed } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ChangeModelOwner',
  components: { LabelWithHoverIcon },
  setup(props, context) {
    const { router } = useRouter()

    const existingOwner = computed(() => store.state.model.owner)
    const newOwner = ref('')
    const shareWithMe = ref(true)
    const loading = ref(false)

    const onSubmit = () => {
      loading.value = true
      const payload = {
        new_owner: newOwner.value,
        share_with_me: shareWithMe.value,
      }
      coreService.modelApi.changeModelOwnership(store.state.model.id, payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Changed model owner',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.root.$bvModal.hide('change-model-ownership-modal')
          store.dispatch('model/closeWorkspace')
          router.push({ name: 'model_browse' })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to change model owner',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }
    const onReset = () => {
      newOwner.value = ''
      shareWithMe.value = true
    }

    return {
      existingOwner,
      newOwner,
      shareWithMe,
      loading,

      onSubmit,
      onReset,
    }
  },
}
</script>

<template>
  <b-card no-body :class="($store.state.model.description || $store.state.model.scope) ? '' : 'mb-0'">
    <b-card-body class="d-inline-flex justify-content-between">

      <!-- Issues -->
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-danger"
          >
            <feather-icon
              size="24"
              icon="LoaderIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ issuesCount ? issuesCount : '-' }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Active Issues
          </b-card-text>
        </b-media-body>
      </b-media>

      <!-- Behaviour Nodes -->
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-warning"
          >
            <font-awesome-icon
              :icon="['fas', 'sitemap']"
              class="font-medium-5"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ behaviourNodesCount ? behaviourNodesCount : '-' }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Behaviour Nodes
          </b-card-text>
        </b-media-body>
      </b-media>

      <!-- Components -->
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-info"
          >
            <font-awesome-icon
              :icon="['fas', 'cube']"
              class="font-medium-5"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ componentsCount ? componentsCount : '-' }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Components
          </b-card-text>
        </b-media-body>
      </b-media>

      <!-- Requirements -->
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-warning"
          >
            <feather-icon
              size="24"
              icon="CheckSquareIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ requirementsCount ? requirementsCount : '-' }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Requirements
          </b-card-text>
        </b-media-body>
      </b-media>

      <!-- Test Cases -->
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-success"
          >
            <font-awesome-icon
              :icon="['fas', 'vials']"
              class="font-medium-5"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ testsCount ? testsCount : '-' }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Test Cases
          </b-card-text>
        </b-media-body>
      </b-media>

    </b-card-body>
  </b-card>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import router from '@/router'
import axiosIns from '@/libs/axios'

export default {
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    onMounted(() => { fetchCounts() })

    const behaviourNodesCount = ref(0)
    const componentsCount = ref(0)
    const issuesCount = ref(0)
    const requirementsCount = ref(0)
    const testsCount = ref(0)

    const fetchCounts = () => {
      axiosIns
        .get(`/api/v2/models/${modelId}/stats`)
        .then(({ data }) => {
          behaviourNodesCount.value = data.behaviourNodes
          componentsCount.value = data.components
          issuesCount.value = data.issues
          requirementsCount.value = data.requirements
          testsCount.value = data.tests
        })
    }

    return {
      behaviourNodesCount,
      componentsCount,
      issuesCount,
      requirementsCount,
      testsCount,
    }
  },
}
</script>

<template>
  <router-link :to="namedRoute" class="link">
    <b-card :title="title" class="mx-auto">
      <hr>
      <b-card-body>
        <font-awesome-icon :icon="icon" class="font-large-5" />
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
export default {
  name: 'CardModelWorkspace',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      required: true,
    },
    namedRoute: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.link {
  /* Remove default URL formatting */
  text-decoration: inherit;
  color: inherit;
  text-align: center;
}
.link:hover {
  color: #F7991C;
}
</style>

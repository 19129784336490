<template>
  <b-modal
    id="modal-migrate-snapshot-to-live-model"
    centered
    title="Snapshot Conversion Confirmation"
  >
    <div v-if="loading" class="text-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Loading..." />
    </div>
    <div
      v-else
      id="convert-modal-body"
      class="p-1"
    >
      <p>
        <span class="text-warning font-weight-bold">Warning</span>: Converting this snapshot to a model is a permanent action.
      </p>
      <p>
        Are you sure you wish to convert this snapshot to a model?
      </p>
      <p class="text-danger font-weight-bold">
        This operation may take a long time, please be patient. The snapshot will not remain after the conversion.
      </p>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        :disabled="loading"
        @click="cancel"
      >
        No, leave it
      </b-button>

      <b-button
        variant="danger"
        :disabled="loading"
        @click="convertSnapshot"
      >
        Convert snapshot
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'MigrateSnapshotToLiveModelModal',
  data: () => ({
    loading: false,
  }),
  methods: {
    convertSnapshot() {
      this.loading = true
      const { modelId } = router.currentRoute.params
      coreService
        .post(`/v1/model/${modelId}/snapshot/convert`, {}, {
          headers: {
            'X-Target': store.state.model.database,
          },
        })
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Migration Success',
              text: 'The snapshot has been successfully migrated to a live model.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Migration error',
              text: `Failed to migrate the model snapshot. ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
          this.$bvModal.hide('modal-migrate-snapshot-to-live-model')
          this.$store
            .dispatch('model/closeWorkspace')
            .then(() => {
              router.push({ name: 'model_browse' })
            })
        })
    },
  },
}
</script>

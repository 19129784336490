<template>
  <div>
    <workspace-header class="mb-2" />

    <b-container>
      <model-stats class="w-100" />

      <div class="d-inline-flex w-100 justify-content-between">
        <b-card
          v-if="$store.state.model.description"
          title="Description"
          class="w-100"
          :class="$store.state.model.scope !== '' ? 'mr-2' : ''"
        >
          <b-card-text>
            <div class="font-small-3">
              {{ $store.state.model.description }}
            </div>
          </b-card-text>
        </b-card>

        <b-card v-if="$store.state.model.scope" title="Scope" class="w-100">
          <b-card-text>
            {{ $store.state.model.scope }}
          </b-card-text>
        </b-card>
      </div>

      <hr>

      <model-dashboard-cards />
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WorkspaceHeader from '@/layouts/components/Workspace/WorkspaceHeader.vue'
import ModelDashboardCards from '@/views/Models/ModelDashboardCards.vue'
import ModelStats from '@/views/Models/ModelStats.vue'

export default {
  name: 'ModelDashboard',
  components: {
    WorkspaceHeader,
    ModelStats,
    ModelDashboardCards,
  },
}
</script>

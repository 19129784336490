<template>
  <b-modal
    id="modal-share-public-model"
    title="Publicly Share Model"
    ok-title="Share model with ALL users"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="outline-secondary"
    @ok="shareModel"
  >
    <p>
      Setting this model to be shared publicly will allow any logged in user to
      <span class="font-weight-bold text-warning">access and modify</span>
      this model.
    </p>
    <p class="mt-1 font-weight-bold text-warning">
      Do you wish to proceed?
    </p>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PubliclyShareModel',
  methods: {
    shareModel() {
      const params = {
        model_id: this.$store.state.model.id,
      }
      this.$http.post('/api/v2/models/share_public', params)
        .then(data => {
          this.$store.commit('model/setPublicShareState', true)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model access set to PUBLIC',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(data => {
          console.error(data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to set model to public access',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
